import { AuthedHttp } from '../../helpers/AuthedHttp';
import { Routes } from './routes';
import { EaseSubscription } from './types';

const AcceptedStatusCode = 202;

export const deleteEaseService = (): Promise<EaseSubscription> => {
  return AuthedHttp.del<any>(Routes.EaseStorage).then((res) => {
    if (res.status === AcceptedStatusCode) {
      return Promise.resolve();
    }
    return res.json();
  });
};

export const addEaseService = (): Promise<void> => {
  return AuthedHttp.post<any>(Routes.EaseStorage).then((res) => {
    if (res.status === AcceptedStatusCode) {
      return Promise.resolve();
    }
    return res.json();
  });
};
