import { AuthedHttp } from '../../helpers/AuthedHttp';
import { ResponseErrorsDto } from '../../helperTypes/responseError';
import { CancelAccountInfoDto } from '../dtos';
import { CancelAccountRequestBody } from '../types/cancelAccount';
import { Routes } from './routes';

export async function cancelAccount(
  cancelAccountRequestBody: CancelAccountRequestBody
): Promise<CancelAccountInfoDto | ResponseErrorsDto> {
  const camAccount = window.Reqres.request('camAccount');

  try {
    if (camAccount && camAccount.new_upgrade_enabled) {
      const response = await AuthedHttp.patch<string>(
        Routes.OfferingsCancelPatch,
        null
      );
      if (response.status === 204) {
        return {};
      }
      const result = await response.json();
      return result as CancelAccountInfoDto | ResponseErrorsDto;
    }

    const response = await AuthedHttp.post<CancelAccountInfoDto>(
      Routes.Cancel,
      transformRequestBody(cancelAccountRequestBody)
    );
    // Post success returns 204 No Content and .json() will fail if you call it on response
    if (response.status === 204) {
      return {};
    }
    const result = await response.json();
    return result as CancelAccountInfoDto | ResponseErrorsDto;
  } catch (err) {
    return {
      errors: [
        {
          field:
            camAccount && camAccount.new_upgrade_enabled
              ? 'offerings_cancel'
              : 'cancel_account',
          message:
            'An error occurred attempting to cancel your ' +
            (camAccount && camAccount.new_upgrade_enabled
              ? 'offerings.'
              : 'account.'),
        },
      ],
    };
  }
}

const transformRequestBody = (
  cancelAccountRequestBody: CancelAccountRequestBody
): CancelAccountInfoDto => {
  const {
    reason,
    comment,
    isInHouse,
    isMovingOn,
    otherProvider,
  } = cancelAccountRequestBody;

  return {
    reason,
    comment,
    other_provider: otherProvider,
    // These are conditionally passed through the request body to match old flow
    ...(isMovingOn !== undefined && { moving: isMovingOn }),
    ...(isInHouse !== undefined && { in_house: isInHouse }),
  } as CancelAccountInfoDto;
};
