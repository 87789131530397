import { AuthedHttp } from '../../helpers/AuthedHttp';
import { Routes } from './routes';
import {
  DowngradePlanRequestDto,
  UgradePlanRequestDto,
  UpdateOfferingsDto,
} from '../dtos';
import { PlanType } from '../types/planType';
import {
  DowngradePlanRequest,
  UpgradePlanRequest,
} from '../types/updatePlanRequest';

import { MarketingCampaignsSubscription } from '../types/marketingCampaignsSubscription';
import { ProductId } from '../types/productId';
import { NewPlanDto } from '../middle_tier/new_plan/service';
import { OES } from '../middle_tier/services/oes/service';
import { SubscriptionPayment } from '../types/subscriptionPayment';
import { createSubscriptionPayment } from '../payment_methods/service';

export const startTrial40k = async (
  marketingCampaignsSubscription: MarketingCampaignsSubscription
): Promise<UpgradePlanRequest> => {
  const upgradePlanRequest = {
    removeEase: false,
    planType: PlanType.EmailApi,
    isUpgrade: true,
    planId: ProductId.SG_EI_TRIAL_40K_V1,
    planName: 'Trial 40K',
  } as UpgradePlanRequest;

  const newPlanRequest = {
    offerings: [
      { name: ProductId.SG_EI_TRIAL_40K_V1 },
      { name: marketingCampaignsSubscription.planId },
    ],
  } as NewPlanDto;

  try {
    const newPlan = await OES.getNewPlanAccount(newPlanRequest);
    upgradePlanRequest.packageId = newPlan.entitlements.compat_combo_uuids[0];
    return upgradePlanRequest;
  } catch (error) {
    console.log('error trying to update to trial 40k', error);
    return Promise.reject('unable to upgrade to trial 40k');
  }
};

export const upgradeWithNewPayment = async (
  subscriptionPayment: SubscriptionPayment,
  upgradePlanRequest: UpgradePlanRequest
) => {
  await createSubscriptionPayment(subscriptionPayment);
  await upgradeService(upgradePlanRequest);
};

export const upgradeService = async (
  request: UpgradePlanRequest | DowngradePlanRequest
): Promise<string> => {
  try {
    let dto;
    const plan_type = request.planType === PlanType.EmailApi ? 'ei' : 'mc';
    if (request.isUpgrade) {
      const r = request as UpgradePlanRequest;
      if (r.paymentInfo) {
        await createSubscriptionPayment(r.paymentInfo);
      }
      dto = {
        remove_ease: r.removeEase,
        package_id: r.packageId,
        is_upgrade: r.isUpgrade,
        plan_type,
      } as UgradePlanRequestDto;
    } else {
      const d = request as DowngradePlanRequest;
      dto = {
        is_upgrade: false,
        package_id: d.packageId,
        plan_type,
      } as DowngradePlanRequestDto;
      if (d.ip) {
        dto.ip = d.ip;
      }
      if (d.reason) {
        dto.reason = d.reason;
      }
    }

    const offeringsUpdate = {
      add: [{ name: request.planId }],
    } as UpdateOfferingsDto;

    const camAccount = window.Reqres.request('camAccount');

    if (camAccount && camAccount.new_upgrade_enabled) {
      const ssapiResponse = await AuthedHttp.patch<string>(
        Routes.OfferingsPatch,
        offeringsUpdate
      );

      if (!ssapiResponse.ok) {
        return Promise.reject('unable to change subscription');
      }

      return Promise.resolve('success');
    }

    const upgradeResponse = await AuthedHttp.put<string>(Routes.Upgrade, dto);
    if (!upgradeResponse.ok) {
      return Promise.reject('unable to change subscription');
    }
    if (request.isUpgrade) {
      // We could fail to charge the cost of the upgrade to the user's card asynchronously.
      // Poll for a payment declined state from OES and show the payment failure banner if needed.
      if (window.SendGridTiara.pollPaymentStatus) {
        window.SendGridTiara.pollPaymentStatus();
      }
    }
    return Promise.resolve('success');
  } catch {
    return Promise.reject('unable to change subscription');
  }
};
